import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/Index/myIndex.vue'
import WelcomeView from '../views/WelcomeView.vue'

const routes = [
  {
    path: '/',
    name: 'welcome',
    meta:{
      title: '你好'
    },
    component: WelcomeView,
  },
  {
    path: '/IndexView',
    name: 'IndexView',
    component: IndexView,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
