import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './utils/all.css'; // 引入全局CSS文件

const app = createApp(App);

// 使用.store和.use(router)来安装 Vuex store 和 Vue Router
app.use(store);
app.use(router);

// 使用 ElementPlus 插件
app.use(ElementPlus);

app.mount('#app');
