<template>
  <div>
    <button @click="get">获取</button>
    <div v-for="item in data" :key="item">
    {{ item}}{{ item }}
    </div>
    

  </div>
</template>

<script setup>
import { ref } from "vue";
import { getArticleType } from "@/api/index"
const data = ref("ddd");
const get = async () => {
  try {
    const response = await getArticleType();
    if (response && response.data) {
      console.log(response.data)
      data.value = response.data;
    } else {
      console.error('响应数据为空或无效。');
    }
  } catch (error) {
    console.error('获取数据时出错：', error);
  }
};
// onBeforeMount( async () => {
//   try {
//     const response = await getArticleType();
//     data.value = response.data;
//   } catch (error) {
//     console.error('获取用户信息时出错：', error);
//   }})
</script>

<style></style>