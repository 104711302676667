<template>
  <section>
    <div class="shell">
      <div class="content">
        <div class="text">
          <h3>Falling leaves animation</h3>
        </div>

        <div class="btn">
        </div>
      </div>

    </div>
    <div v-for="i in 3" :key="i" :class="'set set' + i">
      <div v-for="i in 4" :key="i">
        <img :src="'http://oss.ywystation.cn/Vue/Picture/welcome/leaves' + i + '.png'">
      </div>
      <div v-for="i in 4" :key="i">
        <img :src="'http://oss.ywystation.cn/Vue/Picture/welcome/leaves' + i + '.png'">
      </div>
    </div>
    <div class="box" style="position:fixed;bottom:5px;z-index: 9999;">
      <a style="color: aliceblue;font-size: 0.5em;" href="https://beian.miit.gov.cn/" target="_blank">晋ICP备2023021270号-1</a>
      </div>
  </section>

</template>

<script setup>

</script>

<style lang="scss" scoped src="./Index/myIndex.scss"></style>
<style></style>
