import axios from 'axios'

let baseURL = "http://localhost:9191"

const service = axios.create({
    baseURL,
    timeout:5000
})

service.interceptors.response.use(
    response => {
        if(response.status == 200) {
            return Promise.resolve(response)
        }else{
            return Promise.reject(response)
        }
    },
    error => {
        console.log("出问题了")
    }
)

export default service