import request from '../utils/request'

export const getArticleType = () => {
    return request.get(`/getArticleType`);
  };
  
  export const updateUserProfile = (userId, data) => {
    return request.put(`/users/${userId}`, data);
  };
  
  export const createPost = (data) => {
    return request.post('/posts', data);
  };